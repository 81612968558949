import React, { createContext, useContext, useState } from 'react';

interface FormContext {
  setParentSelect?: React.Dispatch<React.SetStateAction<string>>;
  parentSelect?: string;
}
const FormComponentContext = createContext<FormContext>({});

const FormComponentProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [parentSelect, setParentSelect] = useState(``);
  return (
    <FormComponentContext.Provider
      value={{
        setParentSelect,
        parentSelect,
      }}
    >
      {children}
    </FormComponentContext.Provider>
  );
};

const useFormStateContext = () => {
  return useContext(FormComponentContext);
};

export { useFormStateContext, FormComponentProvider };
