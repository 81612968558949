/* eslint-disable no-use-before-define */
import React, {
  createContext,
  useContext,
  Dispatch,
  useReducer,
  PropsWithChildren,
} from 'react';
import { PageContext } from '../types';

const initialState: PageContext = {};

export type SiteGlobals = {
  pageContext?: PageContext;
  setSiteConfig?: Dispatch<SiteActions>;
};

type SiteActions = { type: 'SET'; payload: PageContext };

function reducer(state: PageContext, action: SiteActions) {
  if (action.type === `SET`) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
}

const SiteContext = createContext<SiteGlobals>({});

const SiteProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SiteContext.Provider
      value={{ pageContext: state, setSiteConfig: dispatch }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useGlobalStateContext = () => {
  return useContext(SiteContext);
};

export { useGlobalStateContext, SiteProvider };
