exports.components = {
  "component---src-modules-template-blogs-tsx": () => import("./../../../src/modules/template/blogs.tsx" /* webpackChunkName: "component---src-modules-template-blogs-tsx" */),
  "component---src-modules-template-events-tsx": () => import("./../../../src/modules/template/events.tsx" /* webpackChunkName: "component---src-modules-template-events-tsx" */),
  "component---src-modules-template-index-tsx": () => import("./../../../src/modules/template/index.tsx" /* webpackChunkName: "component---src-modules-template-index-tsx" */),
  "component---src-modules-template-news-tsx": () => import("./../../../src/modules/template/news.tsx" /* webpackChunkName: "component---src-modules-template-news-tsx" */),
  "component---src-modules-template-press-releases-tsx": () => import("./../../../src/modules/template/press-releases.tsx" /* webpackChunkName: "component---src-modules-template-press-releases-tsx" */),
  "component---src-modules-template-resources-tsx": () => import("./../../../src/modules/template/resources.tsx" /* webpackChunkName: "component---src-modules-template-resources-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

